import { createSlice } from '@reduxjs/toolkit';
import {
  getSearchSuggestions,
  getRecentlySearched,
  getPopularBrands,
  getPopularProducts,
  setHistoryClicks,
  unsetHistoryClicks,
  searchProducts,
  searchResultTotalProducts,
  searchTotalProducts,
  loadMoreProducts,
} from '~store/search/thunks';
import { isWeb } from '~global/screens';

const suggestions = {
  brands: [],
  products: [],
};
export const { actions, reducer } = createSlice({
  name: 'search',
  initialState: {
    keyword: '',
    suggestions,
    recentlySearched: [],
    popular: {
      brands: [],
      products: [],
    },
    products: [],
    totalProducts: '...',
    status: {
      searchSuggestions: 'idle',
      recentlySearched: 'idle',
      setHistoryClicks: 'idle',
      unsetHistoryClicks: 'idle',
      popularBrands: 'idle',
      popularProducts: 'idle',
      products: 'idle',
      totalProducts: 'idle',
      loadMoreProducts: 'idle',
    },
  },
  reducers: {
    setKeyword: (state, { payload }) => {
      state.keyword = payload;
    },
    resetSuggestions: (state) => {
      state.suggestions = suggestions;
    },
    setState: (state, { name, value }) => {
      state[name] = value;
    },
    setStatusApi: (state, { name, value }) => {
      state.status[name] = value;
    },
  },
  extraReducers: {
    [getSearchSuggestions.pending]: (state) => {
      state.status.searchSuggestions = 'pending';
    },
    [getSearchSuggestions.fulfilled]: (state, { payload }) => {
      state.suggestions.brands = payload.brand || [];
      state.suggestions.products = payload['add-review'] || [];

      if (payload.brand.length || payload['add-review'].length) {
        state.status.searchSuggestions = 'succeeded';
      } else {
        state.status.searchSuggestions = 'empty';
      }
    },
    [getRecentlySearched.pending]: (state) => {
      state.status.recentlySearched = 'pending';
    },
    [getRecentlySearched.fulfilled]: (state, { payload }) => {
      state.recentlySearched = payload || [];
      state.status.recentlySearched = 'succeeded';
    },
    [setHistoryClicks.pending]: (state) => {
      state.status.setHistoryClicks = 'pending';
    },
    [setHistoryClicks.fulfilled]: (state) => {
      state.status.setHistoryClicks = 'succeeded';
    },
    [unsetHistoryClicks.pending]: (state) => {
      state.status.unsetHistoryClicks = 'pending';
    },
    [unsetHistoryClicks.fulfilled]: (state) => {
      state.recentlySearched = [];
      state.status.unsetHistoryClicks = 'succeeded';
    },
    [getPopularBrands.pending]: (state) => {
      state.status.popularBrands = 'pending';
    },
    [getPopularBrands.fulfilled]: (state, { payload }) => {
      state.popular.brands = payload || [];
      state.status.popularBrands = 'succeeded';
    },
    [getPopularProducts.pending]: (state) => {
      state.status.popularProducts = 'pending';
    },
    [getPopularProducts.fulfilled]: (state, { payload }) => {
      state.popular.products = payload || [];
      state.status.popularProducts = 'succeeded';
    },
    [searchProducts.rejected]: (state) => {
      state.status.products = 'error';
    },
    [searchProducts.pending]: (state) => {
      state.status.products = 'pending';
    },
    [searchProducts.fulfilled]: (state, { payload }) => {
      state.products = payload.data['add-review'];
      state.status.products = 'succeeded';
    },
    //total
    [searchResultTotalProducts.rejected]: (state) => {
      state.status.totalProducts = 'error';
    },
    [searchResultTotalProducts.pending]: (state) => {
      state.status.totalProducts = 'pending';
    },
    [searchResultTotalProducts.fulfilled]: (state, { payload }) => {
      state.totalProducts = payload.data?.length
        ? payload.data[0]['add-review']
        : 0;
      state.status.totalProducts = 'succeeded';
    },
    [searchTotalProducts.rejected]: (state) => {
      state.status.totalProducts = 'error';
    },
    [searchTotalProducts.pending]: (state) => {
      state.status.totalProducts = 'pending';
    },
    [searchTotalProducts.fulfilled]: (state, { payload }) => {
      state.totalProducts = payload.data;
      state.status.totalProducts = 'succeeded';
    },
    [loadMoreProducts.rejected]: (state) => {
      state.status.loadMoreProducts = 'error';
    },
    [loadMoreProducts.pending]: (state) => {
      state.status.loadMoreProducts = 'pending';
    },
    [loadMoreProducts.fulfilled]: (state, { payload }) => {
      if (isWeb) {
        state.products = payload.data['add-review'];
      } else {
        state.products = [...state.products, ...payload.data['add-review']];
      }
      state.status.loadMoreProducts = 'succeeded';
    },
  },
});

export default reducer;
