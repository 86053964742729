import { createSlice } from '@reduxjs/toolkit';
import { methods, payment } from '~store/shipping/thunks';

export const { actions, reducer } = createSlice({
  name: 'shipping',
  initialState: {
    methods: {},
    selected: {},
    payment: {},
    status: {
      methods: 'idle',
      payment: 'idle',
    },
  },
  reducers: {
    select: (state, { payload }) => {
      for (const key in state.methods) {
        state.methods[key].is_selected = payload === key ? true : false;
      }
      state.selected = state.methods[payload];
    },
    status: (state, { payload: { key, value } }) => {
      state.status[key] = value;
    },
    resetState: (state) => {
      state.methods = {};
      state.selected = {};
      state.payment = {};
    },
    resetStatus: (state) => {
      state.status = status;
    },
  },
  extraReducers: {
    [methods.rejected]: (state) => {
      state.status.methods = 'failed';
    },
    [methods.pending]: (state) => {
      state.status.methods = 'pending';
    },
    [methods.fulfilled]: (state, { payload }) => {
      if (payload) {
        if (payload?.data?.express_on_delivery) {
          delete payload?.data?.express;
        }
        // initialize
        state.methods = payload.data;
        state.selected =
          Object.keys(state.methods)
            .map((item) => state.methods[item])
            .find((item) => item.is_selected) || {};
        state.status.methods = 'succeeded';
      }
    },
    [payment.pending]: (state) => {
      state.status.payment = 'pending';
    },
    [payment.fulfilled]: (state, { payload: { data } }) => {
      state.payment = data;
      state.status.payment = 'succeeded';
    },
  },
});

export default reducer;
