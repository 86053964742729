import { createAsyncThunk } from '@reduxjs/toolkit';
//
import { User } from '~api/user';
import lulla from '~api/lulla';

export const getLillaProfile = createAsyncThunk(
  'tracker/getLillaProfile',
  async (params) =>
    await User.profileRequest(params).then(({ data: { data } }) => data),
);
export const getTrackerTabs = createAsyncThunk(
  'tracker/getTrackerTabs',
  async (params) =>
    await lulla.childTracker(params).then(({ data: { data } }) => data),
);
export const updateTabIndex = createAsyncThunk(
  'tracker/updateTabIndex',
  async ({ id, payload }) => await User.updateUserRequest(id, payload),
);
export const updateChild = createAsyncThunk(
  'tracker/updateChild',
  async ({ id, payload }) => await User.updateUserRequest(id, payload),
);
export const updateTodolist = createAsyncThunk(
  'tracker/updateTodolist',
  async ({ child_id, payload }, { getState }) => {
    const {
      auth: { userId },
    } = getState();

    // update user todolist
    await User.updateUserRequest(userId, payload);

    // get lilla-profile and get child-tracker sequentially
    const profile = await User.profileRequest({
      filter: { user_id: userId, is_detail: true },
    }).then(({ data: { data } }) => data);

    const tracker = await lulla
      .childTracker({
        filter: { user_id: userId, child_id },
        sort: 'position',
      })
      .then(({ data: { data } }) => data);

    return [profile, tracker];
  },
);
export const getNutritionHeading = createAsyncThunk(
  'tracker/getNutritionHeading',
  async (params) =>
    await lulla.nutritionHeading(params).then(({ data: { data } }) => data),
);
export const getNutritions = createAsyncThunk(
  'tracker/getNutritions',
  async (params) =>
    await lulla.nutritionLists(params).then(({ data: { data } }) => data),
);
export const getNutritionDetail = createAsyncThunk(
  'tracker/getNutritionDetail',
  async (params) =>
    await lulla.nutritionListsDetail(params).then(({ data: { data } }) => data),
);
export const getProductRecommendation = createAsyncThunk(
  'tracker/getProductRecommendation',
  async (params) =>
    await lulla
      .productRecommendation(params)
      .then(({ data: { data } }) => data),
);

export const getTrackerFaqs = createAsyncThunk(
  'tracker/getTrackerFaqs',
  async (params) => {
    try {
      const response = await lulla
        .faqs(params)
        .then(({ data: { data } }) => data);
      return response;
    } catch (err) {
      console.log(err);
      throw new Error('Something went wrong!');
    }
  },
);

export const sharedFaq = createAsyncThunk(
  'tracker/sharedFaq',
  async (payload) => {
    try {
      const response = await lulla.sharedFaq(payload);
      return response.data;
    } catch (err) {
      console.log(err);
      throw new Error('Something went wrong!');
    }
  },
);

export const getSharedFaq = createAsyncThunk(
  'tracker/getSharedFaq',
  async (params) => {
    try {
      const response = await lulla.getSharedFaq(params);
      return response.data;
    } catch (err) {
      console.log(err);
      throw new Error('Something went wrong!');
    }
  },
);

export const updateUserPoints = createAsyncThunk(
  'tracker/updateUserPoints',
  async (params) => {
    try {
      const response = await lulla.updateUserPoints(params);
      return response.data;
    } catch (err) {
      console.log(err);
      throw new Error('Something went wrong!');
    }
  },
);

export const getTrackerFeaturedFaqs = createAsyncThunk(
  'tracker/getTrackerFeaturedFaqs',
  async (params) => {
    try {
      const response = await lulla
        .faqs(params)
        .then(({ data: { data } }) => data);
      return response;
    } catch (err) {
      console.log(err);
      throw new Error('Something went wrong!');
    }
  },
);

export const getTrackerFaqDetail = createAsyncThunk(
  'tracker/getTrackerFaqDetail',
  async ({ id }) => {
    try {
      const response = await lulla
        .faqDetail({ id })
        .then(({ data: { data } }) => data);
      return response;
    } catch (err) {
      console.log(err);
      throw new Error('Something went wrong!');
    }
  },
);
