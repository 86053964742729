import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '~api/shipping';

export const methods = createAsyncThunk(
  'shipping/methods',
  async (params) =>
    await api
      .getShippingMethods(params)
      .then(({ data }) => data)
      .catch(console.log),
);
export const payment = createAsyncThunk(
  'shipping/payment',
  async (payload) =>
    await api
      .postUserMeShippings(payload)
      .then(({ data }) => data)
      .catch(console.log),
);
