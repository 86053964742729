import { createSlice } from '@reduxjs/toolkit';

export const { reducer } = createSlice({
  name: 'shoppingBag',
  initialState: {
    specialPriceProducts: [],
    isReloadCart: false,
  },
  reducers: {
    setSpecialPriceProducts: (state, { data }) => {
      state.specialPriceProducts = data;
    },
    setIsReloadCart: (state, { data }) => {
      state.isReloadCart = data;
    },
  },
  extraReducers: {},
});

export default reducer;
